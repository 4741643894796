<template>
    <slot v-if="$slots.default" :wrapper="wrapper"></slot>
    <ODatePicker v-else-if="editorItem.editor === 'time'" v-bind="$attrs" :disabled="disabled" v-model="computedValue" timepickerOnly format="Short Time" :placeholder="editorItem?.config?.placeholder ?? ''"/>
    <ODatePicker v-else-if="editorItem.editor === 'date'" v-bind="$attrs" :disabled="disabled" v-model="computedValue" date format="Short Date" :placeholder="editorItem?.config?.placeholder ?? ''"/>
    <ODatePicker v-else-if="editorItem.editor === 'datetime'" v-bind="$attrs" :disabled="disabled" v-model="computedValue" format="General Date Short Time" timepicker :placeholder="editorItem?.config?.placeholder ?? ''"/>
    <input v-else-if="editorItem.editor === 'bit'" v-bind="$attrs" type="checkbox" :disabled="disabled" :checked="computedValue" @change="updateBitValue" :placeholder="editorItem?.config?.placeholder ?? ''"/>
    <input v-else-if="editorItem.editor === 'number'" v-bind="$attrs" type="number" :disabled="disabled" v-model="computedValue" :placeholder="editorItem?.config?.placeholder ?? ''"/>
    <ONumberEditor v-else-if="editorItem.editor === 'decimal'" v-bind="$attrs" :disabled="disabled" v-model="computedValue" :format="editorItem?.config?.format ?? ''" :placeholder="editorItem?.config?.placeholder ?? ''"/>
    <OTextArea v-else-if="editorItem.editor === 'text' && editorItem.config?.rows > 2" v-bind="$attrs" :rows="editorItem.config.rows" :disabled="disabled" v-model="computedValue" :placeholder="editorItem?.config?.placeholder ?? ''" />
    <input v-else-if="editorItem.editor === 'text'" v-bind="$attrs" type="text" :disabled="disabled" v-model="computedValue" :placeholder="editorItem?.config?.placeholder ?? ''">
    <OrgUnitsLookup v-else-if="editorItem.editor === 'orgunit'" :disabled="disabled" :bind="sel => editorItem.lookupBind(sel)">
        <template #orgunit>
            <input type="text" class="lookup-icon" :disabled="disabled" v-bind="$attrs" :placeholder="editorItem?.config?.placeholder ?? $t('Select...')" v-model="computedValue"/>
        </template>  
    </OrgUnitsLookup>
    <OObjectsLookup v-else-if="editorItem.editor === 'object'" :bind="sel => editorItem.lookupBind(sel)"
        :objectsWhereClause="editorItem.whereClause">
        <template #target="{target}">
            <input :ref="target" type="text" class="lookup-icon" :disabled="disabled" v-bind="$attrs" :placeholder="editorItem?.config?.placeholder ?? $t('Select...')" v-model="computedValue"/>
        </template>  
    </OObjectsLookup>
    <ODataLookup v-else-if="editorItem.editor === 'lookup' && !editorItem.isMultiselect" :dataObject="editorItem.dataObject" :value="computedValue" :bind="sel => editorItem.lookupBind(sel)" :whereClause="editorItem.whereClause" reloadOnWhereClauseChange
        :placeholder="editorItem?.config?.placeholder ?? $t('Select...')" :multiselect="editorItem.isMultiselect" clearMultiselect :contextField="editorItem.contextField" v-bind="$attrs">
        <OColumn v-for="col in editorItem.columns" :field="col.name" :width="col.size" />
    </ODataLookup>
    <ODataLookup v-else-if="editorItem.editor === 'lookup' && editorItem.isMultiselect" :dataObject="editorItem.dataObject" :value="lookupMultiSelectValue" :bind="sel => editorItem.lookupBind(sel)" :whereClause="editorItem.whereClause" reloadOnWhereClauseChange
        :placeholder="editorItem?.config?.placeholder ?? $t('Select...')" :multiselect="editorItem.isMultiselect" :itemLoaded="lkpItem => editorItem.isSelected(lkpItem, row)" :contextField="editorItem.contextField" v-bind="$attrs">
        <OColumn v-for="col in editorItem.columns" :field="col.name" :width="col.size" />
    </ODataLookup>
    <template v-else-if="editorItem.editor === 'local_lookup'">
        <div class="text-muted" v-if="editorItem.isLoading">
            <div class="spinner-border spinner-border-sm"/>
        </div>

        <OSelect v-else-if="editorItem.isMultiselect && editorItem._lookupValues.length <= 10 " v-model="localMultiSelectValue" :openOnMount = "openOnMount" :disabled="disabled" wrapperClass="w-100" :ref="editorInput" :class="$attrs?.class" class="lookup-icon"
            :placeholder="editorItem?.config?.placeholder ?? $t('Select...')" v-bind="$attrs" multiple clearable>
            <option v-for="option in editorItem.lookupValues" :value="option.Value">{{option.Value}}</option>
        </OSelect>

        <ODataLookup v-else-if="editorItem.isMultiselect" :multiselect="editorItem.isMultiselect" :itemLoaded="lkpItem => editorItem.isSelected(lkpItem, row)" :data="multiselectValues" :placeholder="editorItem?.config?.placeholder ?? $t('Select...')"
            :value="lookupMultiSelectValue"
            :bind="sel => lookupMultiSelectValue = sel.map(row => row[editorItem?._props?.config.name])"
            v-bind="$attrs"
            >
            <OColumn :field="editorItem?._props?.config.name"></OColumn>
        </ODataLookup>

        <OSelect v-else-if="editorItem.editor === 'local_lookup' && editorItem._lookupValues.length <= 10" :openOnMount = "openOnMount" v-model="computedValue" :ref="editorInput" :disabled="disabled" wrapperClass="w-100" :class="$attrs?.class" class="lookup-icon"
            :placeholder="editorItem?.config?.placeholder ?? $t('Select...')" v-bind="$attrs" clearable>
            <option v-for="option in editorItem.lookupValues" :value="option.Value">{{option.Value}}</option>
        </OSelect>

        <ODataLookup v-else-if="editorItem.editor === 'local_lookup'" :value="computedValue" 
            :data="lookupValues"
            :disabled="disabled" class="lookup-icon"
            :placeholder="editorItem?.config?.placeholder ?? $t('Select...')" :bind="sel => computedValue = sel[editorItem?._props?.config.name]"
            v-bind="$attrs">
            <OColumn :field="editorItem?._props?.config.name"></OColumn>
        </ODataLookup>
    </template>
</template>

<script setup lang="ts">
import type { DataItemModel } from 'o365-dataobject';

import { ODatePicker, OSelect, OTextArea } from 'o365-ui-components';
import { OOrgUnitsLookup as OrgUnitsLookup, OObjectsLookup } from 'o365-system-lookups';
import { ODataLookup } from 'o365-datalookup';
import PropertiesEditor from './controls.PropertiesEditor.ts';
import { ref, computed, watch, useAttrs } from 'vue';

const props = defineProps<{
    row: DataItemModel, 
    config: {
        dataType: 'text' | 'number' | 'bool' | 'datetime' | 'date',
    },
    disabled?: boolean,
    openOnMount?:boolean,
}>();

const emit = defineEmits<{
    (e: 'update:modelValue', value: any): void
}>();


const modelValue = defineModel();

const attrs = useAttrs();


const multiselectValues = computed(() => {
    return editorItem.value._lookupValues;
});

const lookupValues = computed(() => {
    return editorItem.value._lookupValues?.map(item => ({[editorItem.value._props?.config.name]: item.Value}));
});

const computedValue =  modelValue;

const lookupMultiSelectValue = computed({
      get() { 
        if(modelValue.value){
            try{
                return JSON.parse(modelValue.value).map(x=>x).join(", ");
            }catch{
                return modelValue.value?.join(', ')
            }
          
        }
        return modelValue.value?.split(', ')
        },
    set(value) {
        //const newValue = value?.join(',') ?? null;
       // const newValue = value?JSON.stringify(value):null;
        //computedValue.value = value;
        computedValue.value = value?JSON.stringify(value):null;
      
    }
})

const localMultiSelectValue = computed({
    get() { 
        if(modelValue){
            return modelValue.value;
        }
        return modelValue.value?.split(', ')
        },
    set(value) {

        computedValue.value = value?JSON.stringify(value):null;
      
    }
})

function updateBitValue(pEvent) {
    const value = pEvent.target.checked ? 1 : 0;
    computedValue.value = value;
}


// TODO: Check if there's a better way to pass editable value that is not an object to a slot
const wrapper = {};
Object.defineProperty(wrapper, 'value', {
    get() { return computedValue.value },
    set(val) {computedValue.value = val}
});

const editorItem = ref(new PropertiesEditor(props, (pValue: any) => computedValue.value = pValue));
const editorInput = ref(null);
editorItem.value.initialize();
/*
function activateEditor() {
    console.log("editor activate fnc",editorInput.value);
}

defineExpose({ activateEditor });*/
</script>

<style scoped>
    [placeholder]{
        text-overflow:ellipsis;
    }
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        text-overflow:ellipsis;
    }
    ::-moz-placeholder { /* Firefox 19+ */
        text-overflow:ellipsis;
    }
    :-ms-input-placeholder { /* IE 10+ */
        text-overflow:ellipsis;
    }
    :-moz-placeholder { /* Firefox 18- */
        text-overflow:ellipsis;
    }
</style>
